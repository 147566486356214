/* App.css */
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

body, html {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  background-color: #1F2022; 
  color: #fff; 
  scroll-behavior: smooth;
  overflow-x: hidden;
}

#root {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
}

.app {
  width: 100%;
  max-width: 1000px; 
  padding: 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #1F2022;
}

/* Global styles for mobile devices */
@media (max-width: 768px) {
  body, html {
    font-size: 14px; 
  }
  #root {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh; 
  }
  .app {
    max-height: 4000px;
    padding: 20px; 
  }
}
